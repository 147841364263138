import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import CustomButton from "../CustomButton.web";
import KitchenDrawer from "../KitchenDrawer.web";
import CustomTypographyWeb from "../CustomTypography.web";
import CustomFormCheckBox from "../../../components/src/CustomFormCheckBox.web";
import { AccessTimeRounded } from "@material-ui/icons";
import moment from "moment";

interface Props extends WithStyles<any> {
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSubmit(values: any): void;
}

const FORM_CHECKBOXES = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

function FormInputTimeList({
  timeList,
  onSelect,
}: {
  timeList: Date[];
  onSelect: (date: Date) => void;
}) {
  const handleClick = (meridiem: "AM" | "PM", time: Date) => {
    if (meridiem === "AM") {
      onSelect(time);
    } else {
      const newTime = moment(time).add(12, "hours");
      onSelect(newTime.toDate());
    }
  };

  return (
    <ul className="form__input__timeList">
      {timeList.map((time, index) => {
        return (
          <li key={index}>
            <span>{moment(time).format("HH:mm")}</span>
            <div>
              <span
                role="button"
                className="selected"
                onClick={handleClick.bind(undefined, "AM", time)}
              >
                AM
              </span>{" "}
              <span
                role="button"
                onClick={handleClick.bind(undefined, "PM", time)}
              >
                PM
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export class CustomServicespecificsettingsadmin2OpeningTimesDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getTimeOfDayList = () => {
    let list = [];

    for (let i = 0; i <= 12; i++) {
      const date1 = moment().hours(i).minutes(0).seconds(0).milliseconds(0);
      list.push(date1.toDate());

      const date2 = moment().hours(i).minutes(30).seconds(0).milliseconds(0);
      list.push(date2.toDate());
    }

    return list;
  };

  render(): React.ReactNode {
    const { classes, open, loading, onClose, onSubmit } = this.props;

    return (
      <KitchenDrawer
        open={open}
        title="Special Schedule"
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              Cancel
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formAddSpecialSchedule"
            >
              {loading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Apply"
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={{
              open: null,
              close: null,
            }}
            onSubmit={onSubmit}
            data-testid="formikAddSpecialSchedule"
          >
            {(props) => {
              return (
                <Form
                  id="formAddSpecialSchedule"
                  data-testid="formAddSpecialSchedule"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <div className="form__input__times">
                      <div className="form__input__time">
                        <CustomTypographyWeb>
                          {props.values.open
                            ? moment(props.values.open).format("hh:mm A")
                            : "Open time"}
                        </CustomTypographyWeb>
                        <AccessTimeRounded />
                        <FormInputTimeList
                          timeList={this.getTimeOfDayList()}
                          onSelect={(date: Date) => {
                            props.setFieldValue("open", date);
                          }}
                        />
                      </div>

                      <div className="form__input__time">
                        <CustomTypographyWeb>
                          {props.values.close
                            ? moment(props.values.close).format("HH:mm A")
                            : "Close time"}
                        </CustomTypographyWeb>
                        <AccessTimeRounded />
                        <FormInputTimeList
                          timeList={this.getTimeOfDayList()}
                          onSelect={(date: Date) => {
                            props.setFieldValue("close", date);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form__input__checkboxes">
                      <p className="form__input__title">Select day</p>

                      <div className="form__input__checkboxesList">
                        {FORM_CHECKBOXES.map((value) => {
                          return (
                            <CustomFormCheckBox
                              name=""
                              key={value}
                              value={value}
                              label={
                                <CustomTypographyWeb className="form__input__checkboxLabel">
                                  {value}
                                </CustomTypographyWeb>
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      marginBlock: -16,

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },

      "& .form__input__title": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 18.78 / 16,
        fontWeight: 600,
        color: "#333",
        marginBlock: "18px 15px",
      },

      "& .form__input__checkboxLabel": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 24 / 16,
        color: "#0F172A",
        textTransform: "capitalize",
      },

      "& .form__input__checkboxesList": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
      },

      "& .form__input__times": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        "& .form__input__time": {
          flex: 1,
          padding: "10px 8px",
          border: "1px solid #CBD5E1",
          borderRadius: 8,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          "&:hover .form__input__timeList": {
            display: "block",
          },
        },
        "& .form__input__timeList": {
          display: "none",
          position: "absolute",
          backgroundColor: "#fff",
          top: "100%",
          left: 0,
          right: 0,
          height: 135,
          overflow: "auto",
          zIndex: 1,
          borderRadius: "0px 0px 8px 8px",
          border: "inherit",
          boderTopWidth: 0,
          marginBlock: 0,
          listStyle: "none",
          padding: 12,
          "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&:hover > span": {
              color: "#F66",
              fontWeight: 700,
            },
            "&  span": {
              fontFamily: "Inter",
              fontSize: theme.typography.pxToRem(16),
              lineHeight: 22 / 16,
              transition: "all 200ms ease",
            },
            "& > div": {
              display: "none",
              "& > [role='button']": {
                cursor: "pointer",
                transition: "all 200ms ease",
                "&.selected": {
                  color: "#F66",
                  fontWeight: 700,
                },
                "&:hover": {
                  color: "#FF6666d8",
                  fontWeight: 700,
                },
              },
            },
            "&:hover > div": {
              display: "block",
            },
          },
        },
      },
      "& .portal": {
        flex: 1,
        position: "relative",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export default withStyles(styles)(
  CustomServicespecificsettingsadmin2OpeningTimesDrawer
);
