import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";

export enum AdminRestaurantStatus {
  Busy = "busy",
  Open = "open",
  Close = "close",
}

export interface Props extends WithStyles {
  status: AdminRestaurantStatus;
}

const styles = (theme: Theme) =>
  createStyles({
    name: {
      "&.open": {
        color: "#48CD49",

        "&::before": {
          border: "2px solid #48CD4933",
          backgroundColor: "#48CD49",
        },
      },

      "&.closed": {
        color: "#FF6666",

        "&::before": {
          border: "2px solid #FF666633",
          backgroundColor: "#FF6666",
        },
      },

      "&.busy": {
        color: "#F59E0B",

        "&::before": {
          border: "2px solid #F59E0B33",
          backgroundColor: "#F59E0B",
        },
      },

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        marginRight: 8,
        boxSizing: "border-box",
      },
    },
  });

export class AdminRestaurantStatusTag extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, status } = this.props;

    return (
      <>
        {(() => {
          switch (status) {
            case AdminRestaurantStatus.Open:
              return <Box className={clsx(classes.name, "open")}>Open</Box>;

            case AdminRestaurantStatus.Close:
              return <Box className={clsx(classes.name, "closed")}>Closed</Box>;

            case AdminRestaurantStatus.Busy:
              return <Box className={clsx(classes.name, "Busy")}>Busy</Box>;

            default:
              return <></>;
          }
        })()}
      </>
    );
  }
}

export default withStyles(styles)(AdminRestaurantStatusTag);
