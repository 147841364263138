// Customizable Area Start
import React from "react";
import {
  Box,
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Theme,
  InputAdornment,
} from "@material-ui/core";
import { Close, Menu, Search } from "@material-ui/icons";
import NavigationMenuAdminController, {
  Props,
} from "./NavigationMenuAdminController.web";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
  matchPath,
} from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTypography from "../../../components/src/CustomTypography.web";
import clsx from "clsx";
import { logout, bgKitchenNav, notification, pickupLogo } from "./assets";
import CustomInputField from "../../../components/src/CustomInputField.web";
import AdminLogoutDialog from "../../../components/src/AdminLogoutDialog.web";
import { Skeleton } from "@material-ui/lab";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },

    appBar: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - 265px)`,
        marginLeft: 265,
      },

      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
      },
    },

    appBarHead: {
      position: "relative",
      height: 9,

      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FF6666",
      },

      "&::after": {
        position: "absolute",
        content: '""',
        top: 0,
        right: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FFCC00",
      },
    },

    appBarHeadToolbar: {
      flexWrap: "wrap",
      borderBottom: "1px solid #EDEDED",

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },

    welcome: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontWeight: "700 !important" as any,
    },

    pageName: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    appBarTool: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        order: 3,
        width: "100%",
        marginTop: 12,
      },
    },

    appBarInfo: {
      marginLeft: 15,
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        order: 2,
      },
    },

    appBarNotification: {
      backgroundColor: "#F8F8F8",
      border: "1px solid #EDEDED",
      width: 44,
      height: 44,
    },

    appBarNotificationBadge: {
      "& .MuiBadge-dot": {
        minWidth: 5,
        height: 5,
      },

      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#2EBD6B",
      },
    },

    appBarAvatar: {
      backgroundColor: "#FF6666",
      width: 32,
      height: 32,
    },

    main: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 265px)",
      },

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },
    },

    mainToolbar: {
      [theme.breakpoints.down("xs")]: {
        height: 125,
      },
    },

    outletsButton: {
      minWidth: 178,
      minHeight: 44,
    },

    languageSelect: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

      "& fieldset": {
        border: "none",
      },
    },

    outletsButtonContent: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      width: "100%",
    },

    languageMenuItem: {
      display: "flex",
      alignItems: "center",
    },

    languageMenuItemFlag: {
      width: 24,
      height: 18,
      objectFit: "cover",
    },

    languageMenuItemName: {
      marginLeft: 8,
      fontSize: "12px !important",
      lineHeight: "18px !important",
    },

    drawerMobile: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    drawerMobilePaper: {
      width: 265,
      backgroundColor: "#FFFBF4",
      minHeight: "100vh",

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    drawerWeb: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    drawerWebPaper: {
      boxSizing: "border-box",
      width: 265,
      backgroundColor: "#FFFBF4",
    },

    iconButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    nav: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    search: {
      "& svg": {
        color: "#FF6666",
      },
    },
  });

export interface AdminRoute {
  to: string;
  name: string;
  icon?: string;
  component?: React.ReactNode;
  child?: boolean;
}

class AdminPageComponent extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  async componentWillMount() {
    await this.props.initAdminPage();
  }

  async componentWillUnmount() {
    await this.props.setCurrentAdminPage({
      to: "",
      name: "",
    });
  }
  kitchenNavigate = (to: string) => {
    const { routes } = this.props;
    const currentAdminPage = routes.find(
      (route: { to: string; name: string }) =>
        matchPath(to, {
          path: route.to,
          exact: true,
          strict: false,
        })
    );

    if (!currentAdminPage) {
      return;
    }

    this.props.history.push(to);
  };

  render() {
    const {
      setCurrentAdminPage,
      component: Component,
      id,
      navigation,
    } = this.props;

    const newNavigation = {
      setCurrentAdminPage,
      ...navigation,
      ...this.props,
      kitchenNavigate: this.kitchenNavigate,
    };

    return <Component navigation={newNavigation} id={id} />;
  }
}

const AdminPage = withRouter(AdminPageComponent);

const ComingSoon = () => (
  <Box>
    <CustomTypography variant="h2">Coming soon !</CustomTypography>
  </Box>
);

const navMenuAdminInnerStyles = (theme: Theme) =>
  createStyles({
    navInner: {
      paddingTop: 20,
    },

    navTitle: {
      padding: "0 15px",
    },

    navItem: {
      padding: "8px 15px",
      display: "flex",
      alignItems: "center",

      "&.active": {
        color: "#FFFFFF",
        backgroundColor: "#FF6666",

        "& img": {
          filter: "grayscale(100%) brightness(500%)",
        },
      },
    },

    navItemIcon: {
      width: 24,
      height: 24,
    },

    navItemName: {
      marginLeft: 8,

      "& .MuiTypography-root": {
        fontFamily: "Raleway",
        fontSize: 14,
        lineHeight: "24px",
      },
    },
  });

const NavMenuAdminInner = withStyles(navMenuAdminInnerStyles)(
  (props: {
    title: string;
    classes: {
      [key: string]: string;
    };
    currentAdminPage: { name: string; to: string };
    routes: AdminRoute[];

    setCurrentAdminPage(currentAdminPage: { name: string; to: string }): void;
  }) => (
    <Box className={props.classes.navInner}>
      <CustomTypography className={props.classes.navTitle} variant="h5">
        {props.title}
      </CustomTypography>

      <List>
        {props.routes.map((route) => {
          return (
            !route.child && (
              <ListItem
                className={clsx(props.classes.navItem, {
                  active: matchPath(props.currentAdminPage.to, {
                    path: route.to,
                    exact: false,
                    strict: false,
                  }),
                })}
                disableGutters
                button
                component={Link}
                to={route.to}
                key={route.to}
                onClick={() =>
                  props.setCurrentAdminPage({
                    name: route.name,
                    to: route.to,
                  })
                }
                data-test-id={route.name}
              >
                <img
                  src={route.icon}
                  alt="image/svg+xml"
                  className={props.classes.navItemIcon}
                />

                <ListItemText
                  primary={route.name}
                  className={props.classes.navItemName}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </Box>
  )
);

const navMenuAdminStyles = (theme: Theme) =>
  createStyles({
    navMenu: {
      background: `url(${bgKitchenNav})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "100%",
      backgroundSize: "contain",
      height: "100%",
    },

    navMenuToolbar: {
      paddingLeft: 15,
      paddingRight: 15,
    },

    navLogo: {
      width: 126,
      height: 40,
    },

    navDivider: {
      height: 4,
      borderRadius: "initial",
    },

    navMenuBottom: {
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 27,
    },

    navMenuBottomInner: {
      paddingTop: 12,
      paddingBottom: 24,
      borderTop: "1px solid #EDEDED",
    },

    logoutBtn: {
      fontWeight: 400,
      fontFamily: "Raleway",
      fontSize: 14,
      lineHeight: "24px",
    },
  });

const NavMenuAdmin = withStyles(navMenuAdminStyles)(
  (props: {
    classes: {
      [key: string]: string;
    };
    currentAdminPage: { name: string; to: string };
    performanceMonitoringRoutes: AdminRoute[];
    growYourBusinessRoutes: AdminRoute[];
    manageYourBusinessRoutes: AdminRoute[];
    userRoutes: AdminRoute[];

    handleLogout(): void;
    setCurrentAdminPage(currentAdminPage: { name: string; to: string }): void;
  }) => (
    <Box className={props.classes.navMenu}>
      <Toolbar className={props.classes.navMenuToolbar}>
        <img className={props.classes.navLogo} alt="pickup" src={pickupLogo} />
      </Toolbar>

      <NavMenuAdminInner
        data-test-id="performance-monitoring"
        title="Performance Monitoring"
        routes={props.performanceMonitoringRoutes}
        setCurrentAdminPage={props.setCurrentAdminPage}
        currentAdminPage={props.currentAdminPage}
      />

      <Divider color="#EDEDED" className={props.classes.navDivider} />

      <NavMenuAdminInner
        data-test-id="grow-your-business"
        title="Grow your Business"
        routes={props.growYourBusinessRoutes}
        setCurrentAdminPage={props.setCurrentAdminPage}
        currentAdminPage={props.currentAdminPage}
      />

      <Divider color="#EDEDED" className={props.classes.navDivider} />

      <NavMenuAdminInner
        data-test-id="manage-your-business"
        title="Manage your Business"
        routes={props.manageYourBusinessRoutes}
        setCurrentAdminPage={props.setCurrentAdminPage}
        currentAdminPage={props.currentAdminPage}
      />

      <Box className={props.classes.navMenuBottom}>
        <Box className={props.classes.navMenuBottomInner}>
          <CustomButton
            data-test-id="logout-btn"
            className={props.classes.logoutBtn}
            color="inherit"
            variant="text"
            startIcon={<img src={logout} alt="logout" />}
            onClick={props.handleLogout}
          >
            Logout
          </CustomButton>
        </Box>
      </Box>
    </Box>
  )
);
// Customizable Area End

export class NavigationMenuAdmin extends NavigationMenuAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      languages,
      selectedLanguage,
      mobileOpen,
      currentAdminPage,
      search,
      adminLogoutDialog,
      userProfile,
    } = this.state;
    const { classes, navigation } = this.props;

    return (
      <Router>
        <Box className={classes.container}>
          <AppBar position="fixed" className={classes.appBar} color="default">
            <Toolbar className={classes.appBarHeadToolbar}>
              <IconButton
                className={classes.iconButton}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>

              {this.hideNamePages.includes(currentAdminPage.name) ? (
                <CustomTypography className={classes.welcome}>
                  {userProfile ? (
                    `Welcome, ${userProfile?.attributes.user_name ?? ""}  👋`
                  ) : (
                    <Skeleton variant="text" width={120} height={18} />
                  )}
                </CustomTypography>
              ) : (
                <CustomTypography className={classes.pageName} variant="h2">
                  {currentAdminPage.name}
                </CustomTypography>
              )}

              <Box className={classes.appBarTool}>
                <CustomInputField
                  className={classes.search}
                  variant="outlined"
                  placeholder="Search"
                  inputRef={this.inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {search && (
                          <IconButton
                            data-test-id="clear-search-order-id-btn"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={this.clearSearch}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                  onKeyDown={this.search}
                />

                <CustomSelect
                  data-test-id="language-select"
                  className={classes.languageSelect}
                  variant="outlined"
                  value={selectedLanguage}
                  onChange={this.changeLanguage}
                >
                  {languages.map((language) => (
                    <MenuItem
                      className={classes.languageMenuItem}
                      value={language.value}
                      key={language.value}
                    >
                      <img
                        className={classes.languageMenuItemFlag}
                        src={language.flag}
                        alt={language.name}
                      />
                      <CustomTypography
                        variant="subtitle1"
                        className={classes.languageMenuItemName}
                      >
                        {language.name}
                      </CustomTypography>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <Box className={classes.appBarInfo}>
                <Link
                  to="/kitchen/admin/notifications-all"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton
                    className={classes.appBarNotification}
                    onClick={this.setCurrentAdminPage.bind(this, {
                      name: "Notifications All",
                      to: "/kitchen/admin/notifications-all",
                    })}
                  >
                    <Badge
                      className={classes.appBarNotificationBadge}
                      color="primary"
                      variant="dot"
                      overlap="circular"
                    >
                      <img src={notification} alt="notification" />
                    </Badge>
                  </IconButton>
                </Link>
                <Link
                  to="/kitchen/admin/profile"
                  onClick={this.setCurrentAdminPage.bind(this, {
                    name: "Profile",
                    to: "/kitchen/admin/profile",
                  })}
                  style={{ textDecoration: "none" }}
                >
                  {userProfile ? (
                    <Avatar
                      className={classes.appBarAvatar}
                      src={userProfile?.attributes.image_url ?? ""}
                    >
                      {this.getAvatarName()}
                    </Avatar>
                  ) : (
                    <>
                      <Skeleton variant="circle" width={32} height={32} />
                    </>
                  )}
                </Link>
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav" className={classes.nav}>
            <Drawer
              className={classes.drawerMobile}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={this.handleDrawerTransitionEnd}
              ModalProps={{
                keepMounted: true,
              }}
              classes={{
                paper: classes.drawerMobilePaper,
              }}
              onClose={this.handleDrawerClose}
            >
              <NavMenuAdmin
                data-test-id="drawer-mobile"
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentAdminPage={currentAdminPage}
                setCurrentAdminPage={this.setCurrentAdminPage}
                handleLogout={this.handleLogout}
              />
            </Drawer>

            <Drawer
              variant="permanent"
              className={classes.drawerWeb}
              classes={{
                paper: classes.drawerWebPaper,
              }}
              open
            >
              <NavMenuAdmin
                data-test-id="drawer-web"
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentAdminPage={currentAdminPage}
                setCurrentAdminPage={this.setCurrentAdminPage}
                handleLogout={this.openAdminLogoutDialog}
              />
            </Drawer>
          </Box>

          <Box className={classes.main} component="main">
            <Toolbar className={classes.mainToolbar} />
            <Switch>
              {this.allRoutes.map((route: any) => (
                <Route path={route.to} key={route.to} exact={route.exact}>
                  {route.component ? (
                    <AdminPage
                      data-test-id={route.name}
                      id={route.name}
                      component={route.component}
                      setCurrentAdminPage={this.setCurrentAdminPage}
                      navigation={navigation}
                      routes={this.allRoutes}
                      initAdminPage={this.initAdminPage}
                    />
                  ) : (
                    <ComingSoon data-test-id={route.name} />
                  )}
                </Route>
              ))}

              <Redirect from="/kitchen/admin*" to="/kitchen/admin/dashboard" />
            </Switch>
          </Box>

          <AdminLogoutDialog
            data-test-id="admin-logout-dialog"
            open={adminLogoutDialog}
            onClose={this.closeAdminLogoutDialog}
            onLogout={this.handleLogout}
          />
        </Box>
      </Router>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(NavigationMenuAdmin);
// Customizable Area End
