// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

type Notification = {
  id: string;
  attributes: {
    title: string;
    description: string;
    time: string;
    image: string;
    isRead: boolean;
  };
};

export interface Props {}

interface S {
  notifications: Notification[];
}

interface SS {
  id: any;
}

export default class NotificationsAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      notifications: [],
    };
  }

  async componentDidMount() {
    this.setState({
      notifications: MOCK_NOTIFICATIONS,
    });
  }
}

const MOCK_NOTIFICATIONS = [
  {
    id: "1",
    attributes: {
      title: "New Restaurant Registration",
      description:
        "A new restaurant, Mafia, has registered on the platform. Please review their details and approve or reject their registration as appropriate",
      image: "https://picsum.photos/150",
      time: "2h",
      isRead: false,
    },
  },
  {
    id: "2",
    attributes: {
      title: "New Event Announcement",
      description:
        "An exciting event is coming up! Get ready to join us for a night of music, food, and fun.",
      image: "https://picsum.photos/200",
      time: "3h",
      isRead: false,
    },
  },
  {
    id: "3",
    attributes: {
      title: "Product Update",
      image: "https://picsum.photos/250",
      description:
        "We are happy to announce a new update for our product. Check out the latest features and improvements!",
      isRead: true,
      time: "4h",
    },
  },
  {
    id: "5",
    attributes: {
      isRead: true,
      description:
        "Here's our weekly newsletter with all the important updates and news. Don't miss out!",
      time: "6h",
      title: "Weekly Newsletter",
      image: "https://picsum.photos/350",
    },
  },
  {
    id: "7",
    attributes: {
      time: "2 days",
      image: "https://picsum.photos/150",
      title: "Important Announcement",
      description:
        "We have an important announcement to share. Please read the details carefully.",
      isRead: true,
    },
  },
  {
    id: "8",
    attributes: {
      image: "https://picsum.photos/200",
      time: "3 days",
      description:
        "Exciting news! We have released a new feature that will enhance your experience.",

      isRead: true,
      title: "New Feature Release",
    },
  },
  {
    id: "9",
    attributes: {
      title: "Upcoming Maintenance",
      time: "4 days",
      image: "https://picsum.photos/250",
      description:
        "We will be performing scheduled maintenance on our servers. Expect temporary service interruptions.",
      isRead: true,
    },
  },
];

// Customizable Area End
