// Customizable Area Start
import React from "react";

import UserProfileController, {
  ENUM_SOCIAL_TYPE,
  Props,
  schema,
} from "./UserProfileController.web";
import {
  icArrowRight,
  icCamera,
  icCopy,
  icEmail,
  icFacebook,
  icLinkedin,
  icLocation,
  icTrash,
  icTwitter,
  icUpload,
  imgBanner,
} from "./assets";
import { Avatar, ButtonBase, IconButton, styled } from "@material-ui/core";
import { Check, EditRounded } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomFormInputFieldWeb from "../../../components/src/CustomFormInputField.web";
import { Form, Formik } from "formik";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";

const getSocialIcon = (social: { id: string; type: number }) => {
  switch (social.type) {
    case ENUM_SOCIAL_TYPE.FACEBOOK:
      return {
        name: "facebook",
        icon: icFacebook,
      };
    case ENUM_SOCIAL_TYPE.LINKEDIN:
      return {
        name: "linkedin",
        icon: icLinkedin,
      };
    case ENUM_SOCIAL_TYPE.TWITTER:
      return {
        name: "twitter",
        icon: icTwitter,
      };

    default:
      return {
        name: "email",
        icon: icEmail,
      };
  }
};

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { showEdit, userProfile, loading, snackbar } = this.state;

    return (
      <>
        <CustomLoadingBackdropWeb open={loading} />
        <CustomSnackbarWeb
          open={snackbar.open}
          message={snackbar.message}
          type={snackbar.type}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.hideSnackbar}
        />

        <Container className={showEdit ? "showEdit" : undefined}>
          <img alt="" src={imgBanner} className="banner" />
          <CustomButton
            data-test-id="btnEdit"
            className="btnEdit"
            startIcon={<EditRounded />}
            variant="contained"
            color="primary"
            onClick={this.showEdit}
          >
            Edit profile
          </CustomButton>

          <div className="userInfo">
            <div className="userInfo__avatar">
              <Avatar alt="" src={this.getAvatarUrl()}>
                {this.getAvatarUrl() || userProfile?.attributes.user_name}
              </Avatar>
              {showEdit && (
                <>
                  <div className="overlay">
                    <img src={icCamera} alt="" width={24} height={24} />
                    <CustomTypography className="overlay__text">
                      Edit photo
                    </CustomTypography>

                    <div id="menuEditImage">
                      <ButtonBase>
                        <input
                          type="file"
                          className="menuEdiImage__input"
                          accept="image/png, image/jpeg"
                          name="images"
                          onChange={this.handleImageUpload}
                        />
                        <img alt="" src={icUpload} />
                        <CustomTypography>Upload photo</CustomTypography>
                      </ButtonBase>
                      <ButtonBase onClick={this.handleImageRemove}>
                        <img alt="" src={icTrash} />
                        <CustomTypography>Remove photo</CustomTypography>
                      </ButtonBase>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="userInfo__details">
              <CustomTypography className="userInfo__name">
                {userProfile?.attributes.user_name || "No name"}
              </CustomTypography>
              <div className="userInfo__address">
                <img alt="" src={icLocation} />
                <CustomTypography className="userInfo__address__content">
                  {userProfile?.attributes.add_location || "No location"}
                </CustomTypography>
              </div>
              <div className="userInfo__bio">
                <CustomTypography className="userInfo__bio__title">
                  BIO
                </CustomTypography>
                <CustomTypography className="userInfo__bio__description">
                  {userProfile?.attributes.bio || "No bio"}
                </CustomTypography>
              </div>
            </div>
            <div className="userInfo__actions">
              <CustomButton
                data-testid="btnCancel"
                className="btnCancel"
                variant="contained"
                color="primary"
                onClick={this.hideEdit}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="submit"
                form="formikFormUpdateUserProfile"
                data-testid="btnSave"
                className="btnSave"
                startIcon={<Check />}
                variant="contained"
                color="primary"
              >
                Save changes
              </CustomButton>
            </div>
          </div>

          <div className="userSocials">
            {this.extractUserSocialLinks().map((social, index) => {
              const { icon, name } = getSocialIcon(social);

              return (
                <div className="userSocials__link" key={index}>
                  <div className="userSocials__logo">
                    <img src={icon} alt="" />
                  </div>
                  <div>
                    <CustomTypography className="userSocials__platform">
                      {name}
                    </CustomTypography>
                    <CustomTypography className="userSocials__id">
                      {social.id}
                    </CustomTypography>
                  </div>

                  {index === 0 ? (
                    <IconButton
                      className="userSocials__action"
                      data-testid="btnCopyEmail"
                      onClick={this.handleCopyEmail}
                    >
                      <img alt="" src={icCopy} />
                    </IconButton>
                  ) : (
                    <IconButton
                      data-testid="btnOpenSocialMedia"
                      className="userSocials__action"
                      onClick={() => this.handleOpenSocialMediaLink(social.id)}
                    >
                      <img alt="" src={icArrowRight} />
                    </IconButton>
                  )}
                </div>
              );
            })}
          </div>

          <div className="form">
            <Formik
              initialValues={this.getFormInitialValues()}
              validationSchema={schema}
              onSubmit={this.handleSubmit}
              data-testid="formikFormUpdateUserProfile"
              key={showEdit + ""}
            >
              {(props) => {
                return (
                  <Form id="formikFormUpdateUserProfile" className="form">
                    <div className="form__group">
                      <CustomTypography className="form__group__title">
                        Personal information
                      </CustomTypography>

                      <div>
                        <CustomFormInputFieldWeb
                          fullWidth
                          name="name"
                          label="Name"
                          variant="outlined"
                          error={props.touched.name && !!props.errors.name}
                        />
                        <CustomFormInputFieldWeb
                          fullWidth
                          name="email"
                          label="Email"
                          variant="outlined"
                          error={props.touched.email && !!props.errors.email}
                        />
                        <CustomFormInputFieldWeb
                          fullWidth
                          name="location"
                          label="Location"
                          variant="outlined"
                          error={
                            props.touched.location && !!props.errors.location
                          }
                        />
                        <CustomFormInputFieldWeb
                          fullWidth
                          name="bio"
                          multiline
                          maxRows={3}
                          minRows={3}
                          label="Bio"
                          variant="outlined"
                          error={props.touched.bio && !!props.errors.bio}
                        />
                      </div>
                    </div>

                    <div className="form__divider" />

                    <div className="form__group">
                      <CustomTypography className="form__group__title">
                        Social links
                      </CustomTypography>

                      <div>
                        <CustomFormInputFieldWeb
                          fullWidth
                          name={"socials.0"}
                          label="Facebook"
                          variant="outlined"
                          error={
                            props.touched.socials && !!props.errors.socials?.[0]
                          }
                        />
                        <CustomFormInputFieldWeb
                          fullWidth
                          name={"socials.1"}
                          label="Linkedin"
                          variant="outlined"
                          error={
                            props.touched.socials && !!props.errors.socials?.[1]
                          }
                        />
                        <CustomFormInputFieldWeb
                          fullWidth
                          name={"socials.2"}
                          label="Twitter"
                          variant="outlined"
                          error={
                            props.touched.socials && !!props.errors.socials?.[2]
                          }
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
      </>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    position: "relative",
    border: "1px solid #00000014",
    borderRadius: 8,
    maxWidth: "calc(100% - 60px)",

    "& .banner": {
      aspectRatio: 1017 / 223,
      borderBottomRightRadius: 23,
      width: "100%",
      backgroundColor: "#FFDD99",
    },
    "& .btnEdit": {
      position: "absolute",
      top: 20,
      right: 20,
    },
    "& .userInfo": {
      display: "flex",
      gap: 20,
      marginBlockStart: 24,
      marginInlineStart: 40,

      "& .userInfo__avatar": {
        position: "relative",
        width: "100%",
        aspectRatio: 1,
        maxWidth: 156,
        border: "4px solid  #FF6666",
        transform: "translateY(-50%)",
        borderRadius: "50%",

        "& .MuiAvatar-circular": {
          width: "100%",
          height: "auto",
          aspectRatio: 1,
          objectFit: "cover",
          borderRadius: "inherit",
        },

        "& .overlay": {
          borderRadius: "inherit",
          position: "absolute",
          inset: 0,
          backgroundColor: "rgba(0,0,0,0.75)",
          opacity: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",

          "& #menuEditImage": {
            borderRadius: 8,
            padding: 4,
            width: "100%",
            maxWidth: 192,
            boxShadow: "0px 8px 32px 0px #0000000F",
            border: "1px solid #E2E8F0",
            backgroundColor: "#FFF",
            position: "absolute",
            left: "80%",

            "& > button": {
              position: "relative",
            },

            "& .menuEdiImage__input": {
              position: "absolute",
              inset: 0,
              opacity: 0,
            },

            "& .MuiTypography-root": {
              fontFamily: "Inter",
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 400,
              lineHeight: 22 / 14,
              color: "#0F172A",
            },
            "& button": {
              display: "flex",
              width: "100%",
              gap: 8,
              padding: "10px 16px",
              borderRadius: 8,
              "&:hover": {
                backgroundColor: "#FFDD99",
              },
            },
          },
        },
        "& .overlay__text": {
          fontFamily: "Inter",
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 700,
          lineHeight: 22 / 14,
          color: "#FFF",
        },

        "&:hover .overlay": {
          opacity: 1,
        },
      },

      "& .userInfo__name": {
        color: "#000",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: 24 / 28,
      },
      "& .userInfo__address": {
        marginBlockStart: 8,
        display: "inline-flex",
        alignItems: "center",
        "& .userInfo__address__content": {
          color: "#555",
          fontWeight: 500,
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 20 / 16,
        },
      },
      "& .userInfo__bio": {
        marginBlockStart: 48,
        maxWidth: 327,
      },
      "& .userInfo__bio__title": {
        color: "#64748B",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 18 / 12,
      },
      "& .userInfo__bio__description": {
        color: "#334155",
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 24 / 16,
      },
    },

    "& .userSocials": {
      marginBlockStart: 76,

      "& .userSocials__link": {
        display: "flex",
        alignItems: "center",
        padding: "16px 24px",
        gap: 16,
        "& ~ .userSocials__link": {
          borderTop: "1px solid #F1F5F9",
        },

        "& .userSocials__action": {
          marginInlineStart: "auto",
        },
      },

      "& .userSocials__logo": {
        padding: 12,
        background: "#FFF7E7",
        borderRadius: 8,
      },

      "& .userSocials__platform": {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 22 / 14,
        color: "#64748B",
        textTransform: "uppercase",
      },
      "& .userSocials__id": {
        color: "#334155",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        lineHeight: 24 / 16,
      },
    },

    "& .userInfo__actions": {
      display: "none",
      marginInlineStart: "auto",
      marginInlineEnd: 40,
      marginBlockStart: 40,
      maxHeight: 44,
      "& .btnCancel": {
        color: "#F66",
        backgroundColor: "#FFEAEA",
        marginInlineStart: "auto",
        marginInlineEnd: 16,
      },
    },

    "& .form": {
      display: "none",
      padding: "0px 16px 18px 24px",
      "& .form__group": {
        display: "grid",
        gridTemplateColumns: "3fr 6fr",
        gap: 16,
        "& .form__group__title": {
          fontSize: theme.typography.pxToRem(16),
          fontWeight: 700,
          lineHeight: 24 / 16,
          color: "#64748B",
        },
        "& [class*='CustomFormInputField-label']": {
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 700,
          lineHeight: 22 / 14,
          color: "#64748B",
        },
      },
      "& .form__divider": {
        height: 1,
        width: "100%",
        backgroundColor: "#E2E8F0",
        marginBlock: 24,
      },
    },

    "&.showEdit": {
      "& .form, & .userInfo__actions": {
        display: "block",
      },
      "& .userSocials, & .btnEdit, & .userInfo__details": {
        display: "none",
      },
    },
  };
});

// Customizable Area End
