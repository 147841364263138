import {
  Box,
  InputLabel,
  TextFieldProps,
  createStyles,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomInputField from "./CustomInputField.web";
import clsx from "clsx";

export interface Props extends WithStyles {
  description?: React.ReactNode;
  reverse?: boolean;
}

export type CustomFormInputFieldProps = TextFieldProps & Props;

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    "& + &": {
      marginTop: 24,
    },

    "&.is-reverse": {
      alignItems: "flex-end",

      "& .MuiInputBase-input": {
        textAlign: "end",
      },
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
    position: "relative",

    "&.is-required": {
      "&::after": {
        content: "'*'",
        position: "absolute",
        top: 0,
        right: -8,
        color: "#DC2626",
      },
    },
  },

  input: {
    marginTop: 4,

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
    },
  },
});

export class CustomFormInputField extends React.Component<CustomFormInputFieldProps> {
  constructor(props: CustomFormInputFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      name,
      label,
      classes,
      error,
      required,
      description,
      reverse,
      className,
      ...rest
    } = this.props;

    return (
      <Box
        className={clsx(classes?.container, {
          ["is-reverse"]: reverse,
        })}
      >
        {label && (
          <InputLabel
            className={clsx(classes.label, {
              ["is-required"]: required,
            })}
            htmlFor={name}
          >
            {label}
          </InputLabel>
        )}

        {description && <>{description}</>}

        <Field
          className={clsx(classes.input, className)}
          as={CustomInputField}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormInputField);
