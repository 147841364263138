import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  InputAdornment,
  IconButton,
  Theme,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Portal,
} from "@material-ui/core";
import AdminOrderManagementController, {
  Props,
} from "./AdminOrderManagementController.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { Close, SaveAlt, Search } from "@material-ui/icons";
import CustomTable from "../../../components/src/CustomTable";
import AdminOrderManagementMenu from "../../../components/src/AdminOrderManagementMenu.web";
import AdminOrderStatusTag from "../../../components/src/AdminOrderStatusTag.web";
import moment from "moment";
import CustomPagination from "../../../components/src/CustomPagination";
import AdminOrderDetailFormDialog from "../../../components/src/AdminOrderDetailFormDialog.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import AdminOrderDeleteDialog from "../../../components/src/AdminOrderDeleteDialog.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: "100%",
    },

    filterSelectStatus: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    downloadButton: {
      marginLeft: "auto",
    },

    table: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 38,
    },

    orderTable: {
      "& td": {
        padding: 16,
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    orderId: {
      fontFamily: "Inter !important",
    },

    datePlaced: {
      fontFamily: "Inter !important",
    },

    totalAmount: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
    },
  });
// Customizable Area End

export class AdminOrderManagement extends AdminOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      loading,
      timeFilter,
      orderIdSearch,
      statusFilter,
      statusFilterOptions,
      orders,
      pagination,
      orderDetailDialog,
      snackbar,
      orderDeleteDialog,
    } = this.state;

    return (
      <Box className={classes.container}>
        <Box className={classes.actions}>
          <Box className={classes.filter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                className={classes.filterSelect}
                data-test-id="time-select"
                variant="outlined"
                value={timeFilter}
                onChange={(e: any) => this.changeTimeFilter(e.target.value)}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {option.name}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.filterSelectStatus}>
              <CustomSelect
                className={classes.filterSelect}
                data-test-id="status-select"
                placeholder="Order Status"
                variant="outlined"
                value={statusFilter}
                onChange={(e: any) => this.changeStatusFilter(e.target.value)}
              >
                {statusFilterOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {option.name}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <CustomInputField
              data-test-id="search-order-id-input"
              className={classes.searchOrderId}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder="Order ID"
              InputProps={{
                endAdornment: (
                  <>
                    {orderIdSearch && (
                      <IconButton
                        data-test-id="clear-search-order-id-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearSearchOrderId}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <CustomButton
            className={classes.downloadButton}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-order-report-btn"
            onClick={this.downloadAdminReport}
          >
            Download Report
          </CustomButton>
        </Box>

        <Box className={classes.table}>
          <TableContainer>
            <CustomTable className={classes.orderTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Restaurant Name</TableCell>
                  <TableCell>Branch</TableCell>
                  <TableCell>Date Placed</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {orders.length ? (
                  orders.map((order) => (
                    <TableRow
                      key={order.id}
                      data-test-id={`table-row-${order.id}`}
                    >
                      <TableCell className={classes.orderId}>
                        {order.orderNumber}
                      </TableCell>
                      <TableCell className={classes.restaurantName}>
                        {order.restaurantName}
                      </TableCell>
                      <TableCell className={classes.branch}>
                        {order.branch}
                      </TableCell>
                      <TableCell className={classes.datePlaced}>
                        {moment(order.datePlaced).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell className={classes.totalAmount}>
                        {order.totalAmount}
                      </TableCell>
                      <TableCell className={classes.status}>
                        <AdminOrderStatusTag status={order.status} />
                      </TableCell>
                      <TableCell>
                        <AdminOrderManagementMenu
                          data-test-id="menu"
                          deletable={order.status !== "Declined"}
                          onSelectAction={(action) =>
                            this.onSelectAction(order, action)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>No orders</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <AdminOrderDetailFormDialog
          data-test-id="admin-order-detail-dialog"
          dialog={orderDetailDialog}
          onClose={this.closeOrderDetailDialog}
          editAdminOrder={this.editAdminOrder}
          changeOrderDetailDialogMode={this.changeOrderDetailDialogMode}
        />

        <AdminOrderDeleteDialog
          data-test-id="admin-order-delete-dialog"
          dialog={orderDeleteDialog}
          onClose={this.closeOrderDeleteDialog}
          onDelete={this.deleteAdminOrder}
        />

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(AdminOrderManagement);
