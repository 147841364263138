import React from "react";
// Customizable Area Start
import { Box, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { emptyData } from "./assets";

const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    minHeight: 500,
    textAlign: "center",
  },

  icon: {
    marginBottom: 17,
    width: "auto",
    height: 65,
  },
});

export interface Props extends WithStyles<any> {}
// Customizable Area End

export class KitchenMenuManagementEmpty extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, children } = this.props;

    return (
      <Box className={classes.container}>
        <img src={emptyData} alt="empty-data" className={classes.icon} />

        {children}
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagementEmpty);
