import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { ReactNode } from "react";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles {
  open: boolean;
  hideBackdrop?: boolean;
  width?: number;
  title?: string | ReactNode;
  content?: ReactNode;
  actions?: ReactNode;

  onClose(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      padding: "40px 40px 0px",
      position: "relative",
    },

    closeBtn: {
      position: "absolute",
      top: 16,
      right: 16,
    },

    content: {
      padding: "20px 40px",
    },

    actions: {
      borderTop: "1px solid #E2E8F0",
      padding: "24px 40px",
    },
  });

export class CustomDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      content,
      actions,
      open,
      title,
      width,
      hideBackdrop,

      onClose,
    } = this.props;

    return (
      <Dialog
        hideBackdrop={hideBackdrop}
        className={classes.dialog}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width,
          },
        }}
      >
        <DialogTitle className={classes.title}>
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <Close />
          </IconButton>
          {title && (
            <CustomTypography variant="h2" component="span">
              {title}
            </CustomTypography>
          )}
        </DialogTitle>

        <DialogContent className={classes.content}>{content}</DialogContent>

        <DialogActions className={classes.actions}>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomDialog);
