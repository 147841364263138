import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Grid,
  Tab,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import { InfoOutlined, ArrowForwardIos, Star } from "@material-ui/icons";
import DashboardKitchenController, {
  BusinessTabValue,
  BusinessView,
  Props,
} from "./DashboardKitchenController.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { customerReview } from "./assets";
import CustomTypographyIndex from "../../../components/src/CustomTypographyIndex";
import CustomTabs from "../../../components/src/CustomTabs";
import CustomTable from "../../../components/src/CustomTable";
import CustomPagination from "../../../components/src/CustomPagination";

const styles = (theme: any) =>
  createStyles({
    container: {
      "& *": {
        boxSizing: "border-box",
      },
    },

    tabs: {
      paddingBottom: "0 !important",
      marginBottom: -8,
    },

    block: {
      padding: 12,
      borderRadius: 8,
      border: "1px solid #EDEDED",
      minHeight: 300,
      height: "100%",
    },

    blockHeading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    blockDescription: {
      marginTop: 8,
    },

    blockHeadingLink: {
      fontWeight: 600,
    },

    business: {
      marginTop: 10,
    },

    businessSummary: {
      display: "flex",
      justifyContent: "flex-end",
    },

    businessSummaryItem: {
      textAlign: "right",

      "& + &": {
        marginLeft: 34,
      },
    },

    businessSummaryItemIndex: {
      fontSize: 24,
      lineHeight: "29.05px",
    },

    businessSummaryTable: {
      marginTop: 18,
    },

    businessSummaryBottom: {
      marginTop: 13,
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",

      [theme.breakpoints.down("xs")]: {
        gap: 10,
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "initial",
      },
    },

    opsMonitor: {
      marginTop: 10,
    },

    opsMonitorItem: {
      display: "flex",
      alignItems: "center",
      padding: 12,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      minHeight: 78,

      "& + &": {
        marginTop: 10,
      },
    },

    opsMonitorItemIndex: {
      minWidth: 34,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    opsMonitorItemTitle: {
      display: "flex",
      alignItems: "center",
    },

    opsMonitorItemDescription: {
      marginTop: 5,
    },

    opsMonitorItemContent: {
      marginLeft: 10,
    },

    opsMonitorItemIcon: {
      marginLeft: "auto",
    },

    operation: {
      marginTop: 8,
    },

    operationBlock: {
      borderRadius: 8,
      padding: 12,
      backgroundColor: "#EDEDED",
      minHeight: 103,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    operationHeading: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      "& > :first-child": {
        marginRight: "auto",
      },
    },

    operationInfo: {
      display: "flex",
      alignItems: "center",
      marginTop: 5,
    },

    operationInfoIcon: {
      fontSize: 18,
      marginLeft: 5,
      cursor: "pointer",
    },

    operationDuration: {
      marginTop: "auto",
    },

    customerReview: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },

    customerReviewBackground: {
      width: 155,
      height: "auto",
    },

    customerReviewDescription: {
      marginTop: 4,
      maxWidth: 381,
      textAlign: "center",
    },
  });
// Customizable Area End

export class DashboardKitchen extends DashboardKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { businessTab, businessView, restaurantBusinessData } = this.state;

    return (
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.tabs}
          >
            <CustomTabs
              variant="scrollable"
              scrollButtons="auto"
              value={businessTab}
            >
              <Tab
                label="Today"
                value={BusinessTabValue.Today}
                onClick={() => this.changeBusinessTab(BusinessTabValue.Today)}
              />
              <Tab
                label="Yesterday"
                value={BusinessTabValue.Yesterday}
                onClick={() =>
                  this.changeBusinessTab(BusinessTabValue.Yesterday)
                }
              />
              <Tab
                label="7 Days"
                value={BusinessTabValue.SevenDays}
                onClick={() =>
                  this.changeBusinessTab(BusinessTabValue.SevenDays)
                }
              />
              <Tab
                label="30 Days"
                value={BusinessTabValue.ThirtyDays}
                onClick={() =>
                  this.changeBusinessTab(BusinessTabValue.ThirtyDays)
                }
              />
            </CustomTabs>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  Business Summary
                </CustomTypography>

                <CustomTypography
                  variant="button"
                  className={classes.blockHeadingLink}
                >
                  More in Reports
                </CustomTypography>
              </Box>

              <CustomTypography
                className={classes.blockDescription}
                variant="body1"
              >
                Outlet View
              </CustomTypography>

              <Box className={classes.business}>
                <Box className={classes.businessSummary}>
                  <Box className={classes.businessSummaryItem}>
                    <CustomTypography
                      variant="subtitle1"
                      className={classes.businessSummaryItemIndex}
                    >
                      0
                    </CustomTypography>
                    <CustomTypography variant="body1">
                      Total Orders
                    </CustomTypography>
                  </Box>

                  <Box className={classes.businessSummaryItem}>
                    <CustomTypography
                      variant="subtitle1"
                      className={classes.businessSummaryItemIndex}
                    >
                      SAR 0
                    </CustomTypography>
                    <CustomTypography variant="body1">
                      Total Sales
                    </CustomTypography>
                  </Box>
                </Box>

                <Box className={classes.businessSummaryTable}>
                  <TableContainer>
                    <CustomTable>
                      <TableHead>
                        <TableRow>
                          <TableCell>Restaurant name</TableCell>
                          <TableCell align="right">Order Count</TableCell>
                          <TableCell align="right">
                            <TableSortLabel active direction="desc">
                              Sales
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {restaurantBusinessData.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>{data.name}</TableCell>
                            <TableCell align="right">
                              {data.orderCount}
                            </TableCell>
                            <TableCell align="right">
                              {`SAR ${data.sales}`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </CustomTable>
                  </TableContainer>
                </Box>

                <Box className={classes.businessSummaryBottom}>
                  <CustomTabs rounded value={businessView}>
                    <Tab
                      label="Outlet View"
                      value={BusinessView.OutletView}
                      onClick={() =>
                        this.changeBusinessView(BusinessView.OutletView)
                      }
                    />

                    <Tab
                      label="Chart View"
                      value={BusinessView.ChartView}
                      onClick={() =>
                        this.changeBusinessView(BusinessView.ChartView)
                      }
                    />
                  </CustomTabs>

                  <CustomPagination count={8} page={1} variant="text" />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className={classes.block}>
              <CustomTypography variant="h5">Live Ops Monitor</CustomTypography>

              <CustomTypography
                className={classes.blockDescription}
                variant="body1"
              >
                We found some ongoing issues for your outlets
              </CustomTypography>

              <Box className={classes.opsMonitor}>
                <Box className={classes.opsMonitorItem}>
                  <CustomTypographyIndex
                    className={classes.opsMonitorItemIndex}
                    variant="h2"
                    hideIcon
                  >
                    7
                  </CustomTypographyIndex>

                  <Box className={classes.opsMonitorItemContent}>
                    <CustomTypography variant="subtitle2">
                      Offline outlets
                    </CustomTypography>

                    <CustomTypography
                      variant="body1"
                      className={classes.opsMonitorItemDescription}
                    >
                      Current
                    </CustomTypography>
                  </Box>

                  <ArrowForwardIos className={classes.opsMonitorItemIcon} />
                </Box>

                <Box className={classes.opsMonitorItem}>
                  <CustomTypographyIndex
                    className={classes.opsMonitorItemIndex}
                    variant="h2"
                    hideIcon
                    isGood
                  >
                    0
                  </CustomTypographyIndex>

                  <Box className={classes.opsMonitorItemContent}>
                    <CustomTypography variant="subtitle2">
                      Cancelled orders
                    </CustomTypography>

                    <CustomTypography
                      variant="body1"
                      className={classes.opsMonitorItemDescription}
                    >
                      Today
                    </CustomTypography>
                  </Box>

                  <ArrowForwardIos className={classes.opsMonitorItemIcon} />
                </Box>

                <Box className={classes.opsMonitorItem}>
                  <CustomTypographyIndex
                    className={classes.opsMonitorItemIndex}
                    variant="h2"
                    hideIcon
                    isGood
                  >
                    0
                  </CustomTypographyIndex>

                  <Box className={classes.opsMonitorItemContent}>
                    <CustomTypography variant="subtitle2">
                      Delayed orders
                    </CustomTypography>

                    <CustomTypography
                      variant="body1"
                      className={classes.opsMonitorItemDescription}
                    >
                      Today
                    </CustomTypography>
                  </Box>

                  <ArrowForwardIos className={classes.opsMonitorItemIcon} />
                </Box>

                <Box className={classes.opsMonitorItem}>
                  <CustomTypographyIndex
                    className={classes.opsMonitorItemIndex}
                    variant="h2"
                    hideIcon
                    isGood
                  >
                    0
                  </CustomTypographyIndex>

                  <Box className={classes.opsMonitorItemContent}>
                    <CustomTypography
                      variant="subtitle2"
                      className={classes.opsMonitorItemTitle}
                    >
                      1 <Star /> ratings
                    </CustomTypography>

                    <CustomTypography
                      variant="body1"
                      className={classes.opsMonitorItemDescription}
                    >
                      Today
                    </CustomTypography>
                  </Box>

                  <ArrowForwardIos className={classes.opsMonitorItemIcon} />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  Operations Health
                </CustomTypography>

                <CustomTypography
                  variant="button"
                  className={classes.blockHeadingLink}
                >
                  More in Reports
                </CustomTypography>
              </Box>

              <Grid className={classes.operation} container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box className={classes.operationBlock}>
                    <Box className={classes.operationHeading}>
                      <CustomTypography variant="subtitle1">
                        16 Rejections
                      </CustomTypography>

                      <CustomTypographyIndex variant="subtitle1" isGood>
                        33.33
                      </CustomTypographyIndex>
                    </Box>

                    <Box className={classes.operationInfo}>
                      <CustomTypography variant="body1">
                        Vendor Cancellations
                      </CustomTypography>

                      <InfoOutlined className={classes.operationInfoIcon} />
                    </Box>

                    <CustomTypography
                      variant="caption"
                      className={classes.operationDuration}
                    >
                      Last 7 days
                    </CustomTypography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box className={classes.operationBlock}>
                    <Box className={classes.operationHeading}>
                      <CustomTypography variant="subtitle1">
                        1.55%
                      </CustomTypography>

                      <CustomTypographyIndex variant="subtitle1" isIncrease>
                        8 hours 16 min
                      </CustomTypographyIndex>
                    </Box>

                    <Box className={classes.operationInfo}>
                      <CustomTypography variant="body1">
                        Offline time
                      </CustomTypography>

                      <InfoOutlined className={classes.operationInfoIcon} />
                    </Box>

                    <CustomTypography
                      variant="caption"
                      className={classes.operationDuration}
                    >
                      Last 7 days
                    </CustomTypography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box className={classes.operationBlock}>
                    <Box className={classes.operationHeading}>
                      <CustomTypographyIndex variant="subtitle1" isIncrease>
                        12 Min
                      </CustomTypographyIndex>
                    </Box>

                    <Box className={classes.operationInfo}>
                      <CustomTypography variant="body1">
                        Avg. Preparation Time
                      </CustomTypography>

                      <InfoOutlined className={classes.operationInfoIcon} />
                    </Box>

                    <CustomTypography
                      variant="caption"
                      className={classes.operationDuration}
                    >
                      Last 7 days
                    </CustomTypography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box className={classes.operationBlock}>
                    <Box className={classes.operationHeading}>
                      <CustomTypographyIndex variant="subtitle1" isGood>
                        14.5%
                      </CustomTypographyIndex>
                    </Box>

                    <Box className={classes.operationInfo}>
                      <CustomTypography variant="body1">
                        Order Delay Rate
                      </CustomTypography>

                      <InfoOutlined className={classes.operationInfoIcon} />
                    </Box>

                    <CustomTypography
                      variant="caption"
                      className={classes.operationDuration}
                    >
                      Last 7 days
                    </CustomTypography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={classes.block}>
              <Box className={classes.blockHeading}>
                <CustomTypography variant="h5">
                  Customer Reviews
                </CustomTypography>

                <CustomTypography
                  variant="button"
                  className={classes.blockHeadingLink}
                >
                  More in Reviews
                </CustomTypography>
              </Box>

              <Box className={classes.customerReview}>
                <img
                  className={classes.customerReviewBackground}
                  src={customerReview}
                  alt="Customer Review"
                />

                <CustomTypography variant="h6">No reviews yet</CustomTypography>

                <CustomTypography
                  className={classes.customerReviewDescription}
                  variant="body1"
                >
                  You haven't received any new reviews recently. They would
                  appear here, when you receive them
                </CustomTypography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(DashboardKitchen);
