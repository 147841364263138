import React from "react";
// Customizable Area Start
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import CustomFilter from "../../../components/src/CustomFilter.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSeeChangesDataStatus, {
  SeeChangesDataStatus,
} from "../../../components/src/CustomSeeChangesDataStatus.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { SeeChangesData } from "./KitchenMenuManagementController.web";

const styles = createStyles({
  container: {},

  top: {
    display: "flex",
    alignItems: "center",
  },

  action: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    gap: 10,
  },

  seeChanges: {
    marginTop: 24,
  },

  seeChangesTable: {
    fontFamily: "Inter !important",

    "& td": {
      padding: "12px !important",
    },
  },

  pagination: {
    display: "flex",
    justifyContent: "end",
    marginTop: 24,
  },

  switch: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  switchLabel: {
    fontWeight: "600 !important" as any,
  },

  date: {
    fontFamily: "Inter !important",
    minWidth: 200,
  },
});

export interface Props extends WithStyles<any> {
  seeChangesData: SeeChangesData[];
  showNotification: boolean;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  changePage(page: number): void;
  toggleShowNotification(): void;
  updateSeeChangesDataStatus(
    id: string | number,
    value: SeeChangesDataStatus
  ): void;
}
// Customizable Area End

export class KitchenMenuManagementSeeChanges extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      classes,
      seeChangesData,
      showNotification,
      pagination,

      changePage,
      toggleShowNotification,
      updateSeeChangesDataStatus,
    } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.top}>
          <CustomTypography className={classes.title} variant="h2">
            See Changes
          </CustomTypography>

          <Box className={classes.action}>
            <CustomFilter label="Show All" menu={[]} onChange={() => {}} />

            <Box className={classes.switch}>
              <CustomSwitch
                checked={showNotification}
                onChange={toggleShowNotification}
              />

              <CustomTypography className={classes.switchLabel}>
                Email Notification
              </CustomTypography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.seeChanges}>
          <TableContainer>
            <CustomTable className={classes.seeChangesTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Changes</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {seeChangesData.map((data) => (
                  <TableRow key={data.id} data-test-id={`table-row-${data.id}`}>
                    <TableCell className={classes.userTableId}>
                      {data.name}
                    </TableCell>
                    <TableCell>{data.changes}</TableCell>
                    <TableCell className={classes.date}>
                      {data.date
                        ? moment(data.date).format("DD/MM/YYYY hh:mmA")
                        : "No change date"}
                    </TableCell>
                    <TableCell>{data.author}</TableCell>
                    <TableCell>
                      <CustomSeeChangesDataStatus
                        data-test-id="status-menu"
                        value={data.status}
                        onChange={(value) =>
                          updateSeeChangesDataStatus(data.id, value)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(_, page) => changePage(page)}
              showOffset
            />
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagementSeeChanges);
