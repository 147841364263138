import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputAdornment,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Search, Add, AddCircleOutline } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFilter, {
  CustomFilterMenu,
} from "../../../components/src/CustomFilter.web";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import KitchenMenuCategoryAccordion, {
  KitchenMenuCategory,
} from "../../../components/src/KitchenMenuCategoryAccordion.web";
import clsx from "clsx";
import KitchenMenuItemFormDrawer, {
  KitchenMenuItemFormDrawerProps,
} from "../../../components/src/KitchenMenuItemFormDrawer.web";
import KitchenMenuCategoryFormDrawer, {
  KitchenMenuCategoryFormDrawerProps,
} from "../../../components/src/KitchenMenuCategoryFormDrawer.web";
import KitchenMenuManagementEmpty from "./KitchenMenuManagementEmpty.web";
import { KitchenOrderCatalogueStatus } from "../../../components/src/KitchenOrderCatalogueStatusMenu.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    actions: {
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    addFilterButton: {
      backgroundColor: "#FFDD99",
      borderRadius: 26,
    },

    filterGroup: {
      display: "flex",
      alignItems: "center",
      gap: 15,
    },

    search: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    addButton: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        order: 1,
      },

      [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
      },
    },

    category: {
      width: "100%",
      marginTop: 12,
    },

    ribbon: {
      width: "100%",
      height: "100%",
    },

    ribbonOuter: {
      position: "relative",
      margin: "0 20px",
      bottom: 14,

      "&::after": {
        content: "''",
        position: "absolute",
        top: 0,
        right: -11,
        width: 0,
        height: 0,
        borderBottom: "14px solid #333333",
        borderRight: "11px solid transparent",
      },
    },

    ribbonTitle: {
      fontSize: "44px !important",
      lineHeight: "53.25px !important",
      color: "#FFFFFF",
    },

    ribbonInner: {
      display: "flex",
      minWidth: 82,
      minHeight: 107,
      padding: 5,
      background: "#FF6666",
    },

    ribbonContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      background:
        "radial-gradient(732.81% 75.69% at 50.88% 75.69%, #FFD279 0%, #FF6666 100%)",
    },

    ribbonClipPath: {
      clipPath:
        " polygon( 0% 60.026%,0% 0%,99.671% 0%,99.671% 60.026%,99.671% 60.026%,99.646% 60.525%,99.572% 61.018%,99.45% 61.502%,99.28% 61.976%,99.064% 62.438%,98.804% 62.884%,98.5% 63.314%,98.153% 63.726%,97.765% 64.116%,97.336% 64.482%,55.474% 97.573%,55.474% 97.573%,54.486% 98.238%,53.404% 98.755%,52.254% 99.124%,51.056% 99.346%,49.836% 99.42%,48.615% 99.346%,47.418% 99.124%,46.267% 98.755%,45.186% 98.238%,44.197% 97.573%,2.335% 64.482%,2.335% 64.482%,1.907% 64.116%,1.518% 63.726%,1.172% 63.314%,0.867% 62.884%,0.607% 62.438%,0.391% 61.976%,0.222% 61.502%,0.099% 61.018%,0.025% 60.525%,0% 60.026% )",
    },

    menuStrength: {
      minHeight: 104,
      backgroundColor: "#FFFBF4",
      borderRadius: 8,
      marginTop: 24,
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    menuStrengthLevel: {
      position: "relative",
    },

    menuStrengthTitle: {
      fontSize: "22px !important",
      color: "#FF6666",
    },

    menuStrengthCurrentLevel: {
      marginTop: 6,
    },

    menuStrengthDescription: {
      marginTop: 10,
    },

    menuStrengthInner: {
      display: "flex",
      flexDirection: "column",
      padding: "8px 12px",
      width: "100%",
    },

    menuStrengthHeading: {
      display: "flex",
      alignItems: "center",
    },

    menuStrengthViewMore: {
      marginLeft: "auto",
    },

    categoryHeading: {
      display: "flex",
      alignItems: "center",
      gap: 15,
      flexWrap: "wrap",
    },

    categoryTitle: {
      fontSize: "22px !important",
      lineHeight: "25.83px !important",
    },

    categoryData: {
      marginTop: 15,
    },

    categoryAdd: {
      marginLeft: "auto",
    },

    categoryDataItem: {
      "& + &": {
        marginTop: 15,
      },
    },
  });

export interface Props extends WithStyles<any> {
  filter: string;
  filterMenuList: CustomFilterMenu[];
  menuCategories: KitchenMenuCategory[];
  categoryDrawer: KitchenMenuCategoryFormDrawerProps;
  itemDrawer: KitchenMenuItemFormDrawerProps;

  changeFilter(value: string): void;
  openCategoryDrawer(): void;
  closeCategoryDrawer(): void;
  openItemDrawer(): void;
  closeItemDrawer(): void;
  createCategory(values: {
    name: string;
    image: File;
    categories: string[];
  }): void;
  openEditCategoryDrawer(id: string): void;
  editCategory(values: {
    name: string;
    image: File;
    categories: string[];
  }): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenOrderCatalogueStatus
  ): void;
  createItem(values: {
    image: string | File;
    nameEn: string;
    nameAr: string;
    category: string;
    descriptionEn: string;
    descriptionAr: string;
    price: number;
    variants: {
      id: string;
      name: string;
      price: number;
    }[];
  }): void;
}
// Customizable Area End

export class KitchenMenuManagementOverview extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      classes,
      filter,
      filterMenuList,
      menuCategories,
      categoryDrawer,
      itemDrawer,

      openCategoryDrawer,
      closeCategoryDrawer,
      openItemDrawer,
      closeItemDrawer,
      changeFilter,
      createCategory,
      openEditCategoryDrawer,
      editCategory,
      updateKitchenMenuCatalogueStatus,
      createItem,
    } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.actions}>
          <CustomInputField
            className={classes.search}
            variant="outlined"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <Box className={classes.filterGroup}>
            <CustomFilter
              value={filter}
              onChange={changeFilter}
              menu={filterMenuList}
            />

            <CustomButton
              className={classes.addFilterButton}
              endIcon={<Add />}
              variant="contained"
            >
              Add Filter
            </CustomButton>
          </Box>

          <CustomButton
            data-test-id="add-new-btn"
            className={classes.addButton}
            startIcon={<AddCircleOutline />}
            variant="contained"
            color="primary"
            onClick={openItemDrawer}
          >
            Add New
          </CustomButton>
        </Box>

        {menuCategories.length ? (
          <>
            <Box className={classes.menuStrength}>
              <Box className={classes.menuStrengthLevel}>
                <Box className={classes.ribbon}>
                  <Box className={classes.ribbonOuter}>
                    <Box
                      className={clsx(
                        classes.ribbonInner,
                        classes.ribbonClipPath
                      )}
                    >
                      <Box
                        className={clsx(
                          classes.ribbonContent,
                          classes.ribbonClipPath
                        )}
                      >
                        <CustomTypography
                          className={classes.ribbonTitle}
                          variant="subtitle1"
                        >
                          2
                        </CustomTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.menuStrengthInner}>
                <Box className={classes.menuStrengthHeading}>
                  <CustomTypography
                    className={classes.menuStrengthTitle}
                    variant="h2"
                  >
                    Menu Strength
                  </CustomTypography>

                  <CustomTypography
                    className={classes.menuStrengthViewMore}
                    variant="button"
                  >
                    View More
                  </CustomTypography>
                </Box>

                <CustomTypography
                  className={classes.menuStrengthCurrentLevel}
                  variant="body1"
                >
                  Your current Level: Trainee
                </CustomTypography>

                <CustomTypography
                  className={classes.menuStrengthDescription}
                  variant="body1"
                >
                  <CustomTypography variant="button">
                    Keep going!
                  </CustomTypography>{" "}
                  There are still items that need you attention. add photos and
                  descriptions to them quickly to strengthen your menu.
                </CustomTypography>
              </Box>
            </Box>

            <Box className={classes.category}>
              <Box className={classes.categoryHeading}>
                <CustomTypography
                  className={classes.categoryTitle}
                  variant="h2"
                >
                  All Categories ({menuCategories.length})
                </CustomTypography>

                <CustomTypography
                  data-test-id="add-new-category-btn"
                  className={classes.categoryAdd}
                  variant="button"
                  onClick={openCategoryDrawer}
                >
                  Add New Category
                </CustomTypography>
              </Box>

              <Box className={classes.categoryData}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {menuCategories.map(
                      (category, index) =>
                        (index + 1) % 2 !== 0 && (
                          <Box
                            className={classes.categoryDataItem}
                            key={category.id}
                          >
                            <KitchenMenuCategoryAccordion
                              data-test-id={`kitchen-menu-category-accordion-${category.id}`}
                              category={category}
                              openEditCategoryDrawer={openEditCategoryDrawer}
                              updateKitchenMenuCatalogueStatus={
                                updateKitchenMenuCatalogueStatus
                              }
                            />
                          </Box>
                        )
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {menuCategories.map(
                      (category, index) =>
                        (index + 1) % 2 === 0 && (
                          <Box
                            className={classes.categoryDataItem}
                            key={category.id}
                          >
                            <KitchenMenuCategoryAccordion
                              data-test-id={`kitchen-menu-category-accordion${category.id}`}
                              category={category}
                              openEditCategoryDrawer={openEditCategoryDrawer}
                              updateKitchenMenuCatalogueStatus={
                                updateKitchenMenuCatalogueStatus
                              }
                            />
                          </Box>
                        )
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          <KitchenMenuManagementEmpty>
            <CustomTypography>
              You haven't added any menu yet, add them by clicking
              <br />
              "Add New”
            </CustomTypography>
          </KitchenMenuManagementEmpty>
        )}

        <KitchenMenuCategoryFormDrawer
          data-test-id="kitchen-menu-category-form-drawer"
          drawer={categoryDrawer}
          onClose={closeCategoryDrawer}
          createCategory={createCategory}
          editCategory={editCategory}
        />

        <KitchenMenuItemFormDrawer
          data-test-id="kitchen-menu-item-form-drawer"
          drawer={itemDrawer}
          createItem={createItem}
          onClose={closeItemDrawer}
        />
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagementOverview);
