// Customizable Area Start
import React from "react";

import Servicespecificsettingsadmin2OpeningTimesController, {
  Props,
} from "./Servicespecificsettingsadmin2OpeningTimesController.web";
import { styled } from "@material-ui/core";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomServicespecificsettingsadmin2OpeningTimesDrawer from "../../../components/src/servicesspecificsettingsadmin2/CustomServicespecificsettingsadmin2OpeningTimesDrawer.web";
import { imgSchedule } from "./assets";

export default class Servicespecificsettingsadmin2OpeningTimes extends Servicespecificsettingsadmin2OpeningTimesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { showDrawer } = this.state;

    return (
      <>
        <CustomServicespecificsettingsadmin2OpeningTimesDrawer
          loading={false}
          onClose={this.hideDrawer}
          onSubmit={this.hideDrawer}
          open={showDrawer}
        />
        <Container>
          <div className="scheduleListContainer">
            <CustomTypographyWeb variant="h4">
              Regular Schedule
            </CustomTypographyWeb>
            <div>
              <div className="scheduleList__descriptionContainer">
                <CustomTypographyWeb className="scheduleList__description">
                  This is the schedule you follow on a day to day basis
                </CustomTypographyWeb>
                <CustomButton
                  color="primary"
                  variant="text"
                  className="scheduleList__edit"
                  onClick={this.showDrawer}
                >
                  Edit
                </CustomButton>
              </div>
              <ul className="scheduleList">
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Sunday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Monday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Tuesday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Wednesday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Thursday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Friday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
                <li className="scheduleList__item">
                  <CustomTypographyWeb>Saturday</CustomTypographyWeb>
                  <CustomTypographyWeb>11:00AM to 08:00PM</CustomTypographyWeb>
                </li>
              </ul>
            </div>
          </div>

          <div className="scheduleListContainer">
            <CustomTypographyWeb
              variant="h4"
              className="scheduleList__description"
            >
              Special Schedule
            </CustomTypographyWeb>
            <CustomTypographyWeb
              variant="body1"
              className="scheduleList__description"
            >
              This is the schedule your restaurant follows when its regular
              operations are affected.
            </CustomTypographyWeb>

            <div className="scheduleList__specialDescription">
              <img alt="" src={imgSchedule} width={162} />
              <CustomTypographyWeb variant="body1">
                You have no special schedule set
              </CustomTypographyWeb>
              <CustomTypographyWeb variant="body1">
                You don't have any special schedule set for your restaurants.
                Add a new special schedule by clicking on the button below.
              </CustomTypographyWeb>
              <CustomButton
                color="primary"
                variant="text"
                className="scheduleList__add"
                data-testid="btnAddSpecialSchedule"
                onClick={this.showDrawer}
              >
                Add special Schedule
              </CustomButton>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: 10,

    "& .scheduleListCOntainer": {
      padding: 12,
      flex: 1,
      border: "1px solid #EDEDED",
      borderRadius: 8,
    },
    "& .scheduleList__descriptionContainer": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .scheduleList__description": {
      marginBlockStart: 8,
    },
    "& .scheduleList__edit, & .scheduleList__add": {
      display: "inline-flex",
      paddingInline: 0,
    },
    "& .scheduleList__edit": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 16.44 / 14,
      fontWeight: 600,
      justifyContent: "flex-end",
    },
    "& ul.scheduleList": {
      listStyle: "none",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: 15.5,

      "& li.scheduleList__item": {
        display: "flex",
        justifyContent: "space-between",
        "& > p": {
          fontWeight: 600,
        },

        "& p ~ p": {
          fontFamily: "Inter",
        },
      },
    },
    "& .scheduleList__specialDescription": {
      paddingInline: 34,
      marginBlockStart: 20,
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
  };
});

// Customizable Area End
