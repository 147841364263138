// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {}

interface S {
  showDrawer: boolean;
}

interface SS {
  id: any;
}

export default class Servicespecificsettingsadmin2OpeningTimesController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDrawer: false,
    };
  }

  showDrawer = () => {
    this.setState({ showDrawer: true });
  };

  hideDrawer = () => {
    this.setState({ showDrawer: false });
  };
}

// Customizable Area End
