// Customizable Area Start
import React from "react";

import NotificationsAllController, {
  Props,
} from "./NotificationsAllController.web";
import { ButtonBase, IconButton, styled } from "@material-ui/core";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { CheckRounded, MoreVertRounded } from "@material-ui/icons";

export default class NotificationsAll extends NotificationsAllController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { notifications } = this.state;

    return (
      <Container>
        <div className="btnMarkAllContainer">
          <ButtonBase className="btnMarkAll">
            <CheckRounded htmlColor="#334155" />
            <CustomTypography>Mark all as read</CustomTypography>
          </ButtonBase>
        </div>

        {notifications.map((notification) => {
          return (
            <div key={notification.id} className="notification">
              <img
                alt=""
                src={notification.attributes.image}
                width={44}
                className="notification__image"
              />
              <div>
                <CustomTypography
                  className={
                    "notification__title " +
                    (notification.attributes.isRead
                      ? ""
                      : "notification__title--unread")
                  }
                >
                  {notification.attributes.title}
                </CustomTypography>
                <CustomTypography className="notification__description">
                  {notification.attributes.description}
                </CustomTypography>
              </div>
              <div className="notification__actionContainer">
                <IconButton className="notification__action">
                  <MoreVertRounded htmlColor="#334155" />
                </IconButton>
                <CustomTypography className="notification__time">
                  {notification.attributes.time}
                </CustomTypography>
              </div>
            </div>
          );
        })}
      </Container>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    maxWidth: "80%",
    border: "1px solid #00000014",
    borderRadius: 8,
    padding: 24,
    "& .btnMarkAllContainer": {
      padding: "16px 8px",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .btnMarkAll p": {
      paddingInlineStart: 6,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: 22 / 14,
      color: "#334155",
      marginBlockStart: "auto",
    },
    "& .notification": {
      padding: "16px 8px",
      display: "grid",
      gridTemplateColumns: "44px auto 60px",
      gap: 24,
      borderTop: "1px solid #E2E8F0",
    },
    "& .notification__image": {
      borderRadius: "50%",
    },
    "& .notification__title, & .notification__description, & .notification__time":
      {
        color: "#0F172A",
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 20 / 14,
      },
    "& .notification__title": {
      fontWeight: 700,
      "&.notification__title--unread": {
        fontWeight: 600,
        color: "#FF6666",
      },
    },
    "& .notification__time": {
      fontFamily: "Inter",
      color: "#64748B",
    },
    "& .notification__actionContainer": {
      textAlign: "right",
    },
    "& .notification__action": { padding: "0 0 12px 0" },
  };
});

// Customizable Area End
