// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";

enum TABS {
  SALES = 0,
  ORDERS = 1,
  CUSTOMERS = 2,
}

export const configJSON = require("./config");

export interface Props {}

interface S {
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];
  filterTime: string;
  filterTimeList: CustomFilterMenu[];
}

interface SS {
  id: any;
}

export default class AnalyticsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    this.state = {
      tabActive: TABS.ORDERS,
      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.ORDERS, name: "Orders" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],
      filterTime: "m",
      filterTimeList: [
        { id: "0", name: "Weekly", value: "w" },
        { id: "1", name: "Monthly", value: "m" },
        { id: "2", name: "Yearly", value: "y" },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }

  changeActiveTab = (tab: TABS) => {
    this.setState({ tabActive: tab });
  };

  getActiveTabName = () => {
    switch (this.state.tabActive) {
      case TABS.ORDERS:
        return "Orders";
      case TABS.CUSTOMERS:
        return "Customers";
      case TABS.SALES:
        return "Sales";
    }
  };
}

// Customizable Area End
